export default class User
{
  constructor(email, name, phoneNumber) 
  {
    this.email = email;
    this.name = name;
    this.phoneNumber = phoneNumber
  }    

  isValid()
  {
    return this.email && this.name && this.phoneNumber;
  }
}