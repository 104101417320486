<template>
<div>
    <CCol sm="12">
        <CCardBody class="d-flex flex-column">
                <div role="group" class="row form-group">
                    <div class="row">
                        <h2 v-if="baseUser" class="secondary-text-color font-weight-bold col-lg-9 col-md-12 col-sm-12 pt-5">Editar Utilizador</h2>
                        <h2 v-else class="secondary-text-color font-weight-bold col-lg-9 col-md-12 col-sm-12 pt-5">Adicionar Utilizador</h2>
                    </div>
                    <!-- Name -->
                    <div class="col-lg-6 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-user">Nome do Utilizador</label>
                        <input type="text" v-model="user.name" class="form-control" :class="{ 'is-invalid': submitted && hasError('Name') }"/>
                        <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                            <span v-if="!$v.user.name.required">É necessário o Nome do Utilizador</span>
                            <span v-if="errors.Name">{{errors.Name}}</span>
                        </div>
                    </div>
                    <!-- Email -->
                    <div class="col-lg-6 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-user">Email</label>
                        <input type="text" v-model="user.email" class="form-control" :class="{ 'is-invalid': submitted && hasEmailUsernameError }"/>
                        <div v-if="submitted && hasEmailUsernameError" class="invalid-feedback">
                            <span v-if="!$v.user.email.required">É necessário o Email</span>
                            <span v-if="!$v.user.email.email">Email Inválido</span>
                            <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
                        </div>
                    </div>
                    <!-- PhoneNumber -->
                    <div class="col-lg-6 col-md-12 col-sm-12 mt-5 mb-2">
                        <label class="label-user">Telemóvel</label>
                        <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="user.phoneNumber" :class="{ 'is-invalid': submitted && hasError('PhoneNumber') }"/>
                        <div v-if="submitted && hasError('PhoneNumber')" class="invalid-feedback">
                            <span v-if="!$v.user.phoneNumber.required">É necessário número de telemóvel</span>
                            <span v-if="errors.PhoneNumber">{{errors.PhoneNumber}}</span>
                        </div> 
                    </div>
                </div>
            <!-- Action button + loading -->  
            <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
            <CButton v-else type="button" class="btn align-self-end text-light mt-5" @click="submitClicked" id="save-button">Guardar</CButton>
        </CCardBody>
    </CCol>
</div>
</template>
 
<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import User from "@/classes/User";
import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import EventBus from '@/events/bus.js';
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
    components: {
        VuePhoneNumberInput,
    },
    props:{    
        baseUser:
        {
            type: Object,
            default: null,
        }
    },
    data()
    {
        return {
            submitted: false,
            user: new User(),
            loading: false,
        }
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            phoneNumber: { required }
        },
    },
    mounted: function()
    {
        this.resetUser();
    },
    computed:
    {
        ...mapState('account', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        hasEmailUsernameError()
        {
            return this.$v.user[UTILS_HELPER.convertToCamelCase("Email")].$error || Object.prototype.hasOwnProperty.call(this.errors, "DuplicateEmail");
        }
    },
    methods:{
        resetUser(){
            if(!this.baseUser)
            {
                this.user = new User();
            }
            else
            {
                this.user = UTILS_HELPER.deepClone(new User, this.baseUser); 
            }
        },
        submitClicked(){
            this.submitted = true;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;
            this.$emit('submit-requested', this.user);
        },
        hasError(fieldName)
        {
            return this.$v.user[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        goToUsersList(){
            this.$router.push({name: "UsersList"});
        }
    },
    watch:
    {
        processTask: function(val)
        {
            if(val != null)
            {
                if(val)
                {
                    EventBus.$emit("success-alert-requested", { message: 'Executado com sucesso!', primaryButton: 'Confirmar', primaryCallback: this.goToUsersList});
                }
                this.loading = false;
            }
        },
        baseUser: function()
        {
            this.resetUser();
        },
        alertOutput: function()
        {
            // Show pop up in case it is a string error
            // In case it is an array, it is shown on the field
            if(typeof this.alertOutput === 'string' || this.alertOutput instanceof String)
            {
                alert(this.alertOutput);
            }
        }
    }
  }
</script> 
 
<style scoped>
    .label-user{
        color:rgb(78, 84, 200);
        font-weight: bold;
    }

    #save-button{
        background-color:rgb(78, 84, 200);
        border-radius: 10px;
    }

    #ownership-selector > span{
        color:gray;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector > span.active{
        color:rgb(143, 148, 251) ;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector.is-invalid > span
    {
        color: #dc3545;
    }
</style> 