<template>
  <div v-if="user">
    <!-- Edit form -->
    <FormUser :baseUser="user" @submit-requested="submitRequested"></FormUser>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormUser from '../forms/FormUser.vue'

export default {  
  data () {
    return {
      user: null,
    }
  },
  components: { FormUser },
  methods: {
    ...mapActions('account', ['updateUser']),
    submitRequested (user) {
      this.updateUser(user);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.user)
    {
      this.$router.push({ name: 'UsersList', params: { user: this.user } });
    }
    else
    {
      this.user = this.$route.params.user;
    }
  }
}
</script> 
 
<style scoped>
</style> 